import {
  manageuserloginpasswordHeaderData,
  manageuserloginpasswordTableData,
} from "../../../data/data";
import { TableHeader, TableRow } from "../../../component/table/Table";
import styles from "./manageuserloginpassword.module.scss";

const ManageUserLoginPassword = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.tableContainer}>
        <table className={styles.table} cellSpacing={0}>
          <thead>
            <TableHeader
              data={manageuserloginpasswordHeaderData}
              customClass={styles.tableHead}
            />
          </thead>
          <tbody>
            {manageuserloginpasswordTableData.map((item, index) => {
              return (
                <TableRow
                  customClass={styles.tableBodyRow}
                  manageuserloginpasswordTableData={item}
                  key={index}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageUserLoginPassword;
