import styles from "./header.module.scss";
import { Notification, DropdownIcon2, Member } from "../svg-components";
import { useEffect, useState } from "react";
import FilledButton from "../filled-button/FilledButton";
import WaltValue from "./walt-value-popup/WaltValue";
import Popup from "../popup/Popup";
import UserProfilePhoto from "../../assets/images/user.png";

const Header = ({ title, handleClick }) => {
  const [Page, setPage] = useState(title);
  useEffect(() => {
    if (title === "dashboard") {
      setPage("Dashboard");
    } else if (title === "daily-trade-log") {
      setPage("Daily Trade Log");
    } else if (title === "watchlist") {
      setPage("Watchlist");
    } else if (title === "clients") {
      setPage("Clients");
    } else if (title === "crm") {
      setPage("Add New Client");
    } else if (title === "fund-administration") {
      setPage("Fund Administration");
    } else if (title === "clienttransaction") {
      setPage("Client Transaction");
    } else if (title === "clientlist") {
      setPage("Client List");
    } else if (title === "clientstatement") {
      setPage("Client Statement ");
    } else if (title === "pricing") {
      setPage("Pricing");
    } else if (title === "runfees") {
      setPage("Run Fees");
    } else if (title === "fundbenchmark") {
      setPage("Fund Benchmark");
    } else if (title === "factsheets") {
      setPage("Fact Sheet");
    } else if (title === "ifas") {
      setPage("IFAs");
    } else if (title === "reports") {
      setPage("Birthday Report and Daily Events/Task");
    } else if (title === "runfeesppm") {
      setPage("Run Fees PPM");
    } else if (title === "runfees-tradestation") {
      setPage("Run Fees for Trade Station");
    } else if (title === "runfees-ib") {
      setPage("Run Fees for Interactive Brokers");
    } else if (title === "monthendfees-template") {
      setPage("Month End Fees Template");
    } else if (title === "portfoliomanagerfee") {
      setPage("Portfolio Manager Fee");
    } else if (title === "ppm-client-list") {
      setPage("PPM Client List");
    } else if (title === "ppm-client-list-tfsa") {
      setPage("PPM Client List TFSA");
    } else if (title === "allan-gray-client-list") {
      setPage("Allen Gray Client List");
    } else if (title === "trade-station-client-list") {
      setPage("Trade Station Client List");
    } else if (title === "interactive-brokers-client-list") {
      setPage("Interactive Brokers Client List");
    } else if (title === "maintenanceportal" || title === "upload") {
      setPage("Upload CSV Data Files");
    } else if (title === "jseequityportfolio") {
      setPage("JSE Equity Portfolio");
    } else if (title === "safexportfolio") {
      setPage("SAFEX Portfolio");
    } else if (title === "jsetaxfreeaccount") {
      setPage("JSE Tax Free Account(TFSA)");
    } else if (title === "offshoretradestationequity") {
      setPage("Offshore Trade Station Equity");
    } else if (title === "offshoreinteractivebrokerequity") {
      setPage("Offshore Interactive Brokers Equity");
    } else if (title === "allangrayequity") {
      setPage("Allan Gray - Equity");
    } else if (title === "allan-gray-ra") {
      setPage("Allan Gray - RA");
    } else if (title === "allangrayla") {
      setPage("Allan Gray - LA");
    } else if (title === "taxfreeinvestment") {
      setPage("JSE Tax Free Investment Account at PPM Securities");
    } else if (title === "waltcapital") {
      setPage("Walt Capital Global Fund");
    } else if (title === "addnewifas") {
      setPage("Add New IFAs");
    } else if (title === "ifaaumreport") {
      setPage("IFA John Snow AUM Summary");
    } else if (title === "monthlyreports") {
      setPage("Monthly Commission Report");
    } else if (title === "1") {
      setPage("Drago Mijatović");
    } else if (
      title === "runfeesppm" ||
      title === "runfees-tradestation" ||
      title === "runfees-ib" ||
      title === "monthendfees-template" ||
      title === "portfoliomanagerfee" ||
      title === "fundfee" ||
      title === "ppm-client-list" ||
      title === "ppm-client-list-tfsa" ||
      title === "allan-gray-client-list" ||
      title === "trade-station-client-list" ||
      title === "interactive-brokers-client-list"
    ) {
      setPage("Reports");
    } else if (title === "aum-summary") {
      setPage(" AUM Summary");
    } else if (title === "addnewifas") {
      setPage("Add New IFAs");
    } else if (title === "1") {
      setPage("Drago Mijatović");
    } else if (title === "addnewclient") {
      setPage("Add New Client");
    } else if (title === "ppm-model-equity-portfolio") {
      setPage("PPM Model Equity Portfolio");
    } else if (title === "ppm-tfsa-model-portfolio") {
      setPage("PPM TFSA Model Portfolio");
    } else if (title === "walt-capital-global-fund-investor") {
      setPage("Walt Cap Global Port. Investors ");
    } else if (title === "generateifainvoice") {
      setPage("Generate IFA Invoice ");
    } else if (title === "offshore") {
      setPage("Offshore");
    } else if (title === "ifafeesreport") {
      setPage("IFA Fees Report");
    } else if (title === "tradingportal") {
      setPage("Trading Portal");
    } else if (title === "insurance-portal") {
      setPage("Insurance Portal");
    } else if (title === "manage-user-login-password") {
      setPage("Manage User Logins and Passwords");
    }
  }, [title]);

  const [modal, setModal] = useState(false);
  const handleClose = () => {
    setModal(!modal);
  };

  const handleWaltValue = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        {modal && (
          <Popup Children={WaltValue} handleClose={() => handleClose()} />
        )}
        <div className={styles.headerConent}>
          <span className={styles.dashboardText}>{Page}</span>
          {title === "dashboard" ? (
            <div className={styles.waltValuation}>
              <FilledButton
                title="Walt Valuation"
                customClass={styles.waltValueButton}
                handleClick={() => handleWaltValue()}
              />
              <Member fillColor="#004472" />
              <span className={styles.date}>21 September 2022</span>
              <div
                className={styles.dropdownContainer}
                onClick={() => handleClick()}
              >
                <img
                  src={UserProfilePhoto}
                  alt="user"
                  className={styles.userImage}
                />
                <span className={styles.dropdownContent}>Client Name</span>
                <DropdownIcon2 fillColor="#969BA0" />
              </div>
            </div>
          ) : (
            <div className={styles.dropdownContainer}>
              <Member fillColor="#004472" />
              <span className={styles.date}>21 September 2022</span>
              <div
                className={styles.dropdownContainerItems}
                onClick={() => handleClick()}
              >
                <img
                  src={UserProfilePhoto}
                  alt="user"
                  className={styles.userImage}
                />
                <span className={styles.dropdownContent}>Client Name</span>
                <DropdownIcon2 fillColor="#969BA0" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Header;
