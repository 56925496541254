import styles from "./runfeestradestation.module.scss";
import FilledButton from "../../../component/filled-button/FilledButton";
import { useState, useRef } from "react";
import {
  Print,
  DateIcon,
  DropdownIcon2,
} from "../../../component/svg-components";
import AlphabeticFilter from "../../../component/alphabetic-filter/AlphabeticFilter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/libraries.css";
import Search from "../../../component/search/Search";
import DropDown from "../../../component/dropdown/DropDown";
import {
  tabledata,
  runTradeStationTableData,
  runTradeStationHeadData,
  tradeStationTableSumData,
  tradeStationClientData,
  tradeStationClientSumData,
  tradeStationClientHeadData,
} from "../../../data/data";
import { TableHeader, TableRow } from "../../../component/table/Table";
const RunFeesTradeStation = () => {
  const [startDate, setStartDate] = useState(new Date());
  const ref = useRef();
  const [click, setClick] = useState(false);
  const [click2, setClick2] = useState(false);
  const [click3, setClick3] = useState(false);
  const [click4, setClick4] = useState(false);
  const tabledataName = tabledata.map((item) => item.name);
  return (
    <div className={styles.runfeesPPMStyle}>
      <div className={styles.runfeesPPMContainer}>
        <div className={styles.titleButtonContainer}>
          <div className={styles.titleContainer}>
            <p className={styles.titleText}>Trade Station - SA Client</p>
            <p className={styles.endDate}>End April 2022</p>
          </div>
          <div className={styles.printContainer}>
            <FilledButton
              title="Print PDF"
              customClass={styles.printButton}
              icon={<Print fillColor="#0868AA" handleClick={() => {}} />}
              iconCustomClass={styles.iconCustomClass}
              titleCustomClass={styles.titleCustomClass}
            />
          </div>
        </div>
        <AlphabeticFilter title="IFAs" customClass={styles.abcFilter} />
        <div className={styles.buttonDropdownContainer}>
          <div className={styles.datePickerContainer}>
            <DatePicker
              wrapperClassName={styles.datePicker}
              popperClassName={styles.datePick}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd-MMMM-yyyy"
              showFourColumnMonthYearPicker
            />
            <span className={styles.iconContainer}>
              <DateIcon
                fillColor="#969BA0"
                // handleClick={() => handleClickCalenderPop()}
              />
            </span>
          </div>
          <FilledButton title="View" customClass={styles.viewBtn} />

          <div className={styles.dropdownButtonContainer}>
            <div className={styles.dropdownContainerItems}>
              <div ref={ref}>
                {click ? (
                  <DropDown
                    dropdownItems={tabledataName}
                    customClassForContent={styles.dropdownListContent}
                    customClassForItems={styles.dropdownListItems}
                  />
                ) : null}
              </div>
              <span className={styles.dropdownContent}>
                Pierre van der Walt
              </span>
              <span
                className={styles.dropdownIcon}
                onClick={() => setClick((prevState) => !prevState)}
              >
                <DropdownIcon2 fillColor="#969BA0" />
              </span>
            </div>
          </div>
          <div className={styles.dropdownButtonContainer}>
            <div className={styles.dropdownContainerItems}>
              <div ref={ref}>
                {click2 ? (
                  <DropDown
                    dropdownItems={tabledataName}
                    customClassForContent={styles.dropdownListContent}
                    customClassForItems={styles.dropdownListItems}
                  />
                ) : null}
              </div>
              <span className={styles.dropdownContent}>Cap Town</span>
              <span
                className={styles.dropdownIcon}
                onClick={() => setClick2((prevState) => !prevState)}
              >
                <DropdownIcon2 fillColor="#969BA0" />
              </span>
            </div>
          </div>
          <div className={styles.searchFilter}>
            <Search
              placeholder="Search IFAs"
              customClass={styles.search}
              inputCustomClass={styles.input}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table} cellSpacing={0}>
            <thead>
              <TableHeader
                data={runTradeStationHeadData}
                customClass={styles.tableHead}
              />
            </thead>
            <tbody>
              {runTradeStationTableData.map((item, index) => {
                return (
                  <TableRow
                    customClass={styles.tableBodyRow}
                    runTradeStationTableData={item}
                    key={index}
                  />
                );
              })}
              {tradeStationTableSumData.map((item, index) => {
                return (
                  <TableRow
                    customClassForTd={styles.customClassForTd}
                    tradeStationTableSumData={item}
                    key={index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.runfeesPPMContainer}>
        <div className={styles.titleButtonContainer}>
          <div className={styles.titleContainer}>
            <p className={styles.titleText}>Trade Station - CI Client</p>
            <p className={styles.endDate}>End April 2022</p>
          </div>
          <div className={styles.printContainer}>
            <FilledButton
              title="Print PDF"
              customClass={styles.printButton}
              icon={<Print fillColor="#0868AA" handleClick={() => {}} />}
              iconCustomClass={styles.iconCustomClass}
              titleCustomClass={styles.titleCustomClass}
            />
          </div>
        </div>
        <AlphabeticFilter title="IFAs" customClass={styles.abcFilter} />
        <div className={styles.buttonDropdownContainer}>
          <div className={styles.datePickerContainer}>
            <DatePicker
              wrapperClassName={styles.datePicker}
              popperClassName={styles.datePick}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd-MMMM-yyyy"
              showFourColumnMonthYearPicker
            />
            <span className={styles.iconContainer}>
              <DateIcon
                fillColor="#969BA0"
                // handleClick={() => handleClickCalenderPop()}
              />
            </span>
          </div>
          <FilledButton title="View" customClass={styles.viewBtn} />

          <div className={styles.dropdownButtonContainer}>
            <div className={styles.dropdownContainerItems}>
              <div ref={ref}>
                {click3 ? (
                  <DropDown
                    dropdownItems={tabledataName}
                    customClassForContent={styles.dropdownListContent}
                    customClassForItems={styles.dropdownListItems}
                  />
                ) : null}
              </div>
              <span className={styles.dropdownContent}>
                Pierre van der Walt
              </span>
              <span
                className={styles.dropdownIcon}
                onClick={() => setClick3((prevState) => !prevState)}
              >
                <DropdownIcon2 fillColor="#969BA0" />
              </span>
            </div>
          </div>
          <div className={styles.dropdownButtonContainer}>
            <div className={styles.dropdownContainerItems}>
              <div ref={ref}>
                {click4 ? (
                  <DropDown
                    dropdownItems={tabledataName}
                    customClassForContent={styles.dropdownListContent}
                    customClassForItems={styles.dropdownListItems}
                  />
                ) : null}
              </div>
              <span className={styles.dropdownContent}>Cap Town</span>
              <span
                className={styles.dropdownIcon}
                onClick={() => setClick4((prevState) => !prevState)}
              >
                <DropdownIcon2 fillColor="#969BA0" />
              </span>
            </div>
          </div>
          <div className={styles.searchFilter}>
            <Search
              placeholder="Search IFAs"
              customClass={styles.search}
              inputCustomClass={styles.input}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table} cellSpacing={0}>
            <thead>
              <TableHeader
                data={tradeStationClientHeadData}
                customClass={styles.tableHead}
              />
            </thead>
            <tbody>
              {tradeStationClientData.map((item, index) => {
                return (
                  <TableRow
                    customClass={styles.tableBodyRow}
                    tradeStationClientData={item}
                    key={index}
                    id={item.id}
                  />
                );
              })}
              {tradeStationClientSumData.map((item, index) => {
                return (
                  <TableRow
                    customClassForTd={styles.customClassForTd}
                    customClassForTd2={styles.customClassForTd2}
                    tradeStationClientData={item}
                    key={index}
                    id={item.id}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RunFeesTradeStation;
