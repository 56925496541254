import styles from './monthlyfees.module.scss';

const MonthlyFees = ({ feesData, monthlyFeesTitle, totalAmount }) => {
    return (
        <div className={styles.monthlyFeesContainer}>
            <div className={styles.title}><p className={styles.titleText}>{monthlyFeesTitle}</p></div>
            {
                feesData.map((item, index) => {
                    return (
                        <div key={index} className={styles.feesStructure}>
                            <span>{item.title}</span>
                            <span>{item.fees}</span>
                        </div>
                    )
                })
            }
            <div className={styles.totalAmountContainer}>
                <span>TOTAL</span>
                <span>{totalAmount}</span>
            </div>
        </div>
    )
}

export default MonthlyFees;