// import Navlink from "../../component/navlink/Navlink";

const MainPortalMaintenance = ({ children }) => {
  return (
    <>
      {/* <Navlink /> */}
      {children}
    </>
  );
};

export default MainPortalMaintenance;
