export const colors = {
  green1: "#0B6AAB",
  grey1: "#969BA0",
  grey2: "#D3D6E4",
  white: "#ffffff",
  black: "#585858",
  blue1:'#0868AA',
  grey4:'rgba(90, 176, 235, 0.3)',
  white1:'#ffffff',
  brown:'rgba(227, 151, 36, 0.3)'

};
